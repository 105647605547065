@import "./../../vars.scss";


.header {
  padding: 8px 0;
  .header-wrapper {
    width: 97%;
    h1 {
      flex: 1 1 auto;
      img {
        width: 82px;
      }
    }

    .disconnect {
      opacity: 0.8;
      box-shadow: 0px 0px 6px 0px;
    }
  }
}

p.neutral {
  color: $primaryVarColor
}
