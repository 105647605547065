@import "./../../vars.scss";

.mint-wrapper {
  background-color: rgba(87, 90, 131, 0.4);
  backdrop-filter: blur(1px);
  max-width: 1024px;

  .mint-form-wrapper {
    .input {
      height: 36px;
      background-color: $backgroundVarColor;

      .input-handler {
        p {
          padding: 25%;
        }

        .pointer {
          cursor: pointer;
        }
      }
    }

    .mint-btn {
      opacity: 0.8;
      box-shadow: 0px 0px 7px 4px #1f539c;
    }

    .error {
      color: red;
    }
  }

  h2 {
    color: $neutralColor;
  }

  .mint-button {
    background-color: $backgroundVarColor;
    border: 1px solid $primaryColor;
    color: $neutralColor;

    &:hover {
      background: $primaryVarColor;
      border-color: $primaryVarColor;
    }
  }
}
