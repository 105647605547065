@import "~bootstrap/scss/bootstrap";
@import "./vars.scss";

html {
    scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  max-width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  background: url("./images/coin_ufo.png") center center no-repeat;
  background-size: cover;
}

.root {
  min-height: 100vh;
}

.App {
  min-height: 100vh;
  display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 20px;
}

h1 {
  color: $primaryColor;
}

p {
  color: $neutralColor;
  margin-bottom: 0;
}
